import TagManager from 'react-gtm-module';

const googleTagManager = () => {
  const isProdEnv = process.env.REACT_APP_ENVIRONMENT === 'prod';

  // function gtag() {
  //   if (window.dataLayer) {
  //     // eslint-disable-next-line prefer-rest-params
  //     window.dataLayer.push(arguments);
  //   }
  // }

  if (isProdEnv) {
    // console.log('googleTagManager');
    // https://support.cookiebot.com/hc/en-us/articles/360003793854-Google-Tag-Manager-deployment
    // https://support.cookiebot.com/hc/en-us/articles/360016047000-Cookiebot-and-Google-Consent-Mode

    window.dataLayer = window.dataLayer || [];

    // gtag('set', 'ads_data_redaction', true);

    const tagManagerArgs = {
      gtmId: 'GTM-NJ829TZ',
      // auth: gtmAuth,
      // preview: gtmPreview,
      events: {
        purchase: 'Purchase confirmed',
        begin_checkout: 'Checkout',
        add_to_cart: 'Product added to cart',
        remove_from_cart: 'Product removed from cart'
        // userLogin: 'User login'
      }
    };
    TagManager.initialize(tagManagerArgs);
  }
};

export default googleTagManager;
